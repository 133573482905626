import React from 'react';
import Head from 'next/head';
import {
  FullFormScript,
  ReducedFormScript,
} from '@/components/common/external-form';
import { Landing } from '@/components/common/landing';
import { usePageTitle } from '@/hooks/use-page-title';
import { useAppConfig } from '@insurance/app-config';

/**
 * To make req and query available in the CustomDocument
 * @see https://stackoverflow.com/questions/65790930/next-js-access-request-ctx-req-in-getinitialprops-method-in-custom-document
 */
export async function getServerSideProps() {
  return { props: {} };
}

const LandingPage: React.FC = () => {
  const { formType, formBuildTag } = useAppConfig();

  const title = usePageTitle();

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <Landing />

      {formType === 'fullform' ? (
        <FullFormScript formBuildTag={formBuildTag} isModalForm />
      ) : (
        <ReducedFormScript formBuildTag={formBuildTag} isModalForm />
      )}
    </>
  );
};

export default LandingPage;
